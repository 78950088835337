body {
  /* margin: 20px !important; */
  box-sizing: border-box;
  min-width: 375px;
}

.App{
  position: relative; 
  width: 100vw; 
  /* height: 100vh;  */
}

.nav-container{
  position: absolute; /* 부모 요소를 기준으로 위치를 설정 */
  top: 0px;
  width: 100%;
  z-index: 9;
}


.footer-container{

}



/* 
 768px:   미만은 모바일 기기, 
 768px ~ 1024px: 사이는 타블렛, 
 1024px 이상은 데스크탑 기기로 구분
*/


/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) { /*스타일입력*/}

/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1023px) { /*스타일입력*/}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width:480px) and (max-width:767px) { /*스타일입력*/}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (max-width:479px) { /*스타일입력*/}
