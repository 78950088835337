

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid rgb(207, 207, 207);
  border-radius: 20px;
  padding: 10px;
}
.swiper-slide .icon{
  position: absolute;
  width: 45px;
  height: 45px;
  bottom: 10px;
  right: 10px;
}
.swiper-slide a{
  font-size: 15px;
  font-weight: 600;
  text-decoration: none; 
  color: inherit;
  cursor: pointer;
}
.slide-container {
  width: 100%;
  height: 75%;
  margin-bottom: 5px;
}
.swiper-slide span {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slide-container img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.swiper-slide > img > span{
  font-size: 10px;
}

@media (max-width: 400px) {
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 200px;
    height: 200px;
  }
  .swiper-slide .icon{
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 10px;
    right: 10px;
  }
}
