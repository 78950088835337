.navbar-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    position: relative;
    top: 0;
  }
  
  .navbar {
    background-color: #333;
    color: #fff;
    width: 40px; 
    height: 40px;
    border-radius: 50%; 
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: width 2s ease; 
  }
  
  .navbar-brand {
    font-size: 20px;
    cursor: pointer;
  }
  
  .expanded {
    width: 100%; 
    height: auto; 
    border-radius: 5px; 
  }

  .videoWrapper{
    opacity: 0.6;
  }
  .video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    z-index: -1;
  }